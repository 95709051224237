<template>
    <div>
        <div class="row mb-5 align-items-center" v-if="id==0 || isLoaded">
            <div class="col-12">
                <ValidationObserver ref="storeForm">
                    <div class="row">
                        <div class="col-6 col-xs-12">
                            <ValidationProvider name="academic_yer" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('academic_year')">
                                    <academic-years-selectbox
                                        :validate-error="errors[0]"
                                        v-model="form.academic_year"
                                    ></academic-years-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>

                        <div class="col-6 col-xs-12">
                            <ValidationProvider name="module" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('module')">
                                    <parameter-selectbox
                                        :validate-error="errors[0]"
                                        code="prep_modules"
                                        v-model="form.module"
                                    ></parameter-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>

                        <div class="col-xs-12 col-6">
                            <ValidationProvider name="type" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('type')">
                                    <parameter-selectbox
                                        :validate-error="errors[0]"
                                        code="prep_module_types"
                                        v-model="form.type"
                                    ></parameter-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>

                        <div class="col-6 col-xs-12">
                            <ValidationProvider name="not_allow_attendance_updates" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('not_allow_attendance_updates')">
                                    <b-form-checkbox
                                        v-model="form.not_allow_attendance_updates">
                                        {{''}}
                                    </b-form-checkbox>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>

                        <div class="col-6 col-xs-12">
                            <ValidationProvider name="start_date" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('start_date')">
                                    <select-date :validate-error="errors[0]"
                                                 v-model="form.start_date"/>
                                </b-form-group>
                            </ValidationProvider>
                        </div>

                        <div class="col-6 col-xs-12">
                            <ValidationProvider name="end_date" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('end_date')">
                                    <select-date :validate-error="errors[0]"
                                                 v-model="form.end_date"/>
                                </b-form-group>
                            </ValidationProvider>
                        </div>

                        <div class="col-6 col-xs-12">
                            <ValidationProvider name="publish_at" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('preparation_report_card_publication_date')">
                                    <select-date :validate-error="errors[0]"
                                                 v-model="form.publish_at"
                                                 :time="true"
                                    />
                                </b-form-group>
                            </ValidationProvider>
                        </div>

                        <div class="col-6 col-xs-12">
                            <ValidationProvider name="close_edit_at" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('edit_end_date')">
                                    <select-date :validate-error="errors[0]"
                                                 v-model="form.close_edit_at"
                                                 :time="true"
                                    />
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
            <div class="col-12 mt-3 d-flex">
                <b-button @click="store"
                          type="button"
                          variant="primary"
                          class="btn-lg mr-2">
                    {{ $t('save').toUpper() }}
                </b-button>
            </div>
        </div>
    </div>
</template>
<script>
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import PrepModuleService from "@/services/PrepModuleService";
import SelectDate from "@/components/interactive-fields/SelectDate";

export default {
    components: {
        SelectDate,
        AcademicYearsSelectbox,
        ParameterSelectbox,
        ValidationProvider,
        ValidationObserver
    },
    props: ['id'],
    data() {
        return {
            //id: this.$router.params.id,
            form: {},
            memberOptions: [],
            isLoaded: false
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        loadData() {
            if (this.id > 0) {
                PrepModuleService.get(this.id)
                    .then(response => {
                        this.form = response.data.data;
                        this.isLoaded = true;
                    }).catch(error => {
                    if (error.data.message) {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    }
                });
            }
        },
        async store() {
            const isValid = await this.$refs.storeForm.validate();
            if (isValid) {
                let formData = {
                    ...this.form
                };
                formData.not_allow_attendance_updates = 0;
                if (this.form.not_allow_attendance_updates) {
                    formData.not_allow_attendance_updates = 1;
                }
                PrepModuleService.store(formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        if (typeof response.data.data != 'undefined') {
                            this.form = response.data.data;
                        }
                        this.$emit('moduleSaved');
                    })
                    .catch(e => {
                        if (e.data.message) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    });
            }
        }
    }
};
</script>
