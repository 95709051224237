<template>
    <div>
        <app-layout>
	        <template v-slot:header>
	            <Header :title="$t('prep_modules')"
	                    :isNewButton="checkPermission('prepmodule_store')"
	                    @new-button-click="add"
	                    @filter-div-status="datatable.filterStatus=$event">
	            </Header>
	        </template>
	        <template v-slot:header-mobile>
		        <HeaderMobile :title="$t('prep_modules')"
		                      :isNewButton="checkPermission('prepmodule_store')"
		                      @new-button-click="add"
		                      @filter-div-status="datatable.filterStatus=$event">
	            </HeaderMobile>
	        </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="4" lg="3" xl="2">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox
                                v-model="datatable.queryParams.filter.academic_year">
                            </academic-years-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       :lineNumbers="false"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>
            <CommonModal ref="modal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t(id==0?'add_module':'edit') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <module-form v-if="renderComponent" ref="moduleForm" :id="id" v-on:moduleSaved="moduleSaved"></module-form>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import qs from 'qs'
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import PrepModuleService from "@/services/PrepModuleService";
import CommonModal from "@/components/elements/CommonModal";
import ModuleForm from "@/modules/prepModules/pages/ModuleForm";

export default {
    components: {
        ModuleForm,
        AcademicYearsSelectbox,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        ParameterSelectbox,
        CommonModal
    },
    metaInfo() {
        return {
            title: this.$t('prep_modules')
        }
    },
    data() {
        return {
            id: 0,
            renderComponent: false,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('edit'),
                                class: 'ri-edit-box-line',
                                permission: "prepmodule_update",
                                callback: (row) => {
                                    this.id = row.id;
                                    this.$refs.modal.$refs.commonModal.show();
                                    this.forceRerender();
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-2-line',
                                permission: "prepmodule_delete",
                                callback: (row) => {
                                    this.delete(row.id);
                                }
                            },
                        ]
                    },
                    {
                        label: this.toUpperCase('academic_year'),
                        field: 'academic_year',
                        sortable: true,
                    },
                    {
                        label: this.toUpperCase('module'),
                        field: this.getLocaleField('module_name'),
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase('type'),
                        field: this.getLocaleField('type_name'),
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase('start_date'),
                        field: 'start_date',
                        sortable: true,
                    },
                    {
                        label: this.toUpperCase('end_date'),
                        field: 'end_date',
                        sortable: true,
                    },
                    {
                        label: this.toUpperCase('publish_at'),
                        field: 'publish_at',
                        sortable: true,
                    },
                    {
                        label: this.toUpperCase('close_edit_at'),
                        field: 'close_edit_at',
                        sortable: true,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            }
        }
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return PrepModuleService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        add() {
            this.id = 0;
            this.forceRerender();
            this.$refs.modal.$refs.commonModal.show();
        },
        moduleSaved() {
            this.$refs.modal.$refs.commonModal.hide();
            this.filter();
        },
        delete(id) {
            this.deleteModal(() => {
                PrepModuleService.deleteModule(id)
                    .then(response => {
                        this.filter();
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(error => {
                        this.$toast.error(this.$t('api.' + error.data.message));
                    });
            });
        },
        forceRerender() {
            // Remove my-component from the DOM
            this.renderComponent = false;

            this.$nextTick(() => {
                // Add the component back in
                this.renderComponent = true;
            });
        }
    }
};
</script>

